import { DISCOUNT_TYPES, SUBSCRIPTION_STATES } from 'constants/subscriptions';

import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import { Product, SubscriptionProductCodes, IUpcomingProduct } from './product';
import { Currencies } from './currency';

export interface ISubscription {
    expired_date: string;
    external_id: string;
    id: number;
    price: string;
    product: Product;
    product_code: SubscriptionProductCodes;
    purchase_date: string;
    state: SUBSCRIPTION_STATES;
    is_trial: boolean;
    cancelled_at: string;
    upcoming_product: IUpcomingProduct | null;
    discount_type: string | null;
    discount_granted_at: string | null;
    pause_type: SubscriptionPauseType;
    pause_to: string | null;
    is_charge_reminder_on: boolean;
}

export interface ISubscriptionFeedback {
    reason: CancellationReasonRetenoValue;
}

export interface ICancelSubscriptionRequest {
    external_id: string;
    cancellation_reason: CancellationReasonRetenoValue;
}

export interface ICancelSubscription {
    currentSubscription: ISubscription;
    secondSubscription?: ISubscription;
    cancelReason?: CancellationReasonRetenoValue;
}

export interface IFetchDiscountSubscription {
    discount_type: DISCOUNT_TYPES;
    external_id: string;
}

export interface IDiscountSubscription {
    id: string;
    name: string;
    amount: number;
    currency: Currencies;
    trial: boolean;
    subscription_period: number;
    subscription_trial_period: null | number;
    started_from: string;
    product_id: string;
}

export interface IUpdateSubscriptionData {
    product: IDiscountSubscription;
    external_id: string;
    discount_type: DISCOUNT_TYPES;
}

export enum CancelOfferAction {
    Accept = 'accept',
    Cancel = 'cancel',
    Close = 'close',
    Explore = 'explore',
}

export enum SubscriptionPauseType {
    Pause = 'one_month_pause',
    FreeMonth = 'one_month_free',
}

export enum CancelFlowOffers {
    Discount = 'discount',
    Login = 'login',
    Reminder = 'reminder',
    DiscountAfterCancel = 'discount_after_cancel',
    FreeMonth = 'free_month',
    Pause = 'pause',
    ChangePlan = 'change_plan',
    Resume = 'resume',
}
