import { Button, Text, Image } from 'wikr-core-components';
import styled from 'styled-components';

import { borderRadius, color } from 'theme/selectors';

export const Content = styled.div`
    padding-top: 32px;

    @media (max-width: 768px) {
        padding-bottom: 100px;
    }
`;

export const Card = styled.div`
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-radius: 20px;
    overflow: hidden;
    background-color: ${color('decorative-c')};
`;

export const CardContent = styled.div`
    padding: 0 16px;
`;

export const CardHeader = styled(Text)`
    margin-bottom: 20px;
    background-color: ${color('decorative-b')};
`;

export const ListItem = styled.div`
    display: flex;
    gap: 5px;
    margin-bottom: 12px;
`;

export const ItemImage = styled(Image)`
    flex-shrink: 0;
    width: 16px;
`;

export const PauseButton = styled(Button)`
    margin-top: 8px;
    border-radius: ${borderRadius('medium')} !important;
`;
