import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

import { changePathNameToScreenId } from '../helpers';

import { trackClick } from './mainTrackers';

export const sendAnalyticResetContactUsClick = () => {
    trackClick({
        screenIdParam: changePathNameToScreenId(),
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.contactUs,
    });
};
