import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { sendAnalyticCancelOfferClick, trackScreenLoadCancelOffer } from 'services/analytics';

import { useCancelSubscriptionFlow, useDiscountModal } from 'hooks/subscriptions';
import useModals from 'hooks/modals/useModals';
import { useSelector } from 'hooks';

import { getValuesByKey, roundToNearest } from 'helpers/utils';

import SubscriptionsSkeleton from 'components/SubscriptionsTab/components/SubscriptionsSkeleton';
import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import FullScreenModalHeader from 'components/ModalHeaders/FullScreenModalHeader';
import Modal from 'components/Modal';

import * as S from './styled';

import { CancelFlowOffers, CancelOfferAction } from 'types/subscription';

import SubscriptionCards from './SubscriptionCards';

const DiscountModal = () => {
    const { closeModal, openModal } = useModals();
    const { t } = useTranslation();

    const { onCancelSubscription, onChangeSubscription, discountSubscription } = useCancelSubscriptionFlow();

    const cancellationCandidates = useSelector(selectCancellationCandidates);

    const { currentSubscription, currencySign, discountOffer } = useDiscountModal();

    const isDiscountOfferReady = discountOffer && currentSubscription && currencySign;

    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    const onBackButtonClick = () => {
        sendAnalyticCancelOfferClick({
            eventLabel: { subscription_id, offer: CancelFlowOffers.Discount },
            actionEvent: CancelOfferAction.Close,
        });
        openModal(ModalName.CancellationReasonModal);
    };

    useEffect(() => {
        trackScreenLoadCancelOffer({ eventLabel: { subscription_id, offer: CancelFlowOffers.Discount } });
    }, []);

    const sendAnalytics = useCallback(
        (action: CancelOfferAction) => {
            sendAnalyticCancelOfferClick({
                eventLabel: { subscription_id, offer: CancelFlowOffers.Discount },
                actionEvent: action,
            });
        },
        [subscription_id]
    );

    const changeSubscription = useCallback(() => {
        if (discountSubscription) {
            sendAnalytics(CancelOfferAction.Accept);
            onChangeSubscription();
        }
    }, [onChangeSubscription, discountSubscription]);

    const onClose = useCallback(() => {
        sendAnalytics(CancelOfferAction.Close);
        closeModal();
    }, [closeModal]);

    const discount = isDiscountOfferReady
        ? roundToNearest(Math.round(100 * (1 - Number(discountOffer.price) / Number(currentSubscription.price))))
        : null;

    const handleCancelSubscription = useCallback(() => {
        sendAnalytics(CancelOfferAction.Cancel);
        onCancelSubscription();
    }, [onCancelSubscription]);

    return (
        <Modal
            fullscreen
            isOpen
            onClose={onClose}
            customStyles={{ background: '#F3F3F8', padding: 0 }}
            withRoundedCorners={false}
        >
            <FullScreenModalHeader onBackButtonClick={onBackButtonClick} closeModal={onClose} />

            <div className="container-sm">
                <S.DiscountModalContent>
                    {!isDiscountOfferReady ? (
                        <SubscriptionsSkeleton />
                    ) : (
                        <>
                            <div>
                                <Text
                                    medium
                                    center
                                    mb={12}
                                    type="h4"
                                    text={t('subscription.discountOfferModal.title', { percent: discount })}
                                    dataLocator="unsubscribeTitle"
                                />
                                <Text
                                    center
                                    type="large-text"
                                    text={t('subscription.discountOfferModal.descriptionNew', {
                                        percent: discount,
                                    })}
                                    dataLocator="unsubscribeDescription"
                                />
                            </div>
                            <SubscriptionCards
                                currencySign={currencySign}
                                currentSubscription={currentSubscription}
                                onChangeSubscription={changeSubscription}
                                discountPrice={discountOffer.price}
                                discount={discount as number}
                            />
                            <OutlinedButton
                                onClick={handleCancelSubscription}
                                text={t('basics.button.cancelSubscription')}
                                backgroundColor="surface-main"
                                dataLocator="unsubscribeCancelSubscriptionButton"
                            />
                        </>
                    )}
                </S.DiscountModalContent>
            </div>
        </Modal>
    );
};

export default DiscountModal;
