/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, takeLatest, all, put, select } from 'redux-saga/effects';

import api from 'api';

import { UserStore } from 'store/user/types';
import { selectCurrentUser } from 'store/user/selectors';
import { getWorkoutQueryParams } from 'store/personalPlan/helpers';

import {
    generatePlanRequest,
    getCompletedPlanStatisticsSuccess,
    getCurrentPlanSuccess,
    getWorkoutSuccess,
    workoutComplete,
    setCurrentPlanCompletion,
} from './actions';
import { notifyError } from '../notifications/actions';

import * as actionTypes from './actionTypes';

import { isLastDayOfThePlan, isWholePlanCompleted } from 'helpers/workout';
import { addWorkoutStatus } from 'helpers/personalPlan';

import { IPersonalListResponse, IStatisticPersonalPlanResponse, IWorkoutResponse } from 'types/personalPlan';

import { selectCurrentPlanId, selectTotalPlanDays } from './selectors';

export function* getCompletedPlanStatistics() {
    try {
        const currentPlanId: number = yield select(selectCurrentPlanId);

        const personalPlan: IStatisticPersonalPlanResponse = yield call(
            api.personalPlan.getStatisticPersonalPlan,
            currentPlanId
        );

        yield put(getCompletedPlanStatisticsSuccess(personalPlan));
    } catch (error: any) {
        console.error('Complete personal plan error: ', error.message);
    }
}

export function* getCurrentPlan() {
    try {
        const { id, current_day, days, yoga_type }: IPersonalListResponse = yield call(
            api.personalPlan.getCurrentPersonalPlan
        );

        const { workouts, availableCurrentDay } = addWorkoutStatus({ workouts: days, currentDay: current_day });
        const availableCurrentDayIndex = availableCurrentDay - 1;
        const user: UserStore = yield select(selectCurrentUser);
        const workoutParams = getWorkoutQueryParams({ currentDay: days[availableCurrentDayIndex], user, yoga_type });

        yield put(getCurrentPlanSuccess({ id, current_day: availableCurrentDay, days: workouts, yoga_type }));

        if (isWholePlanCompleted(workouts)) yield put(setCurrentPlanCompletion(true));

        const workout: IWorkoutResponse = yield call(api.personalPlan.getWorkout, workoutParams);

        yield put(getWorkoutSuccess(workout));
    } catch (error: any) {
        console.error('Get personal plan error: ', error.message);
    }
}

export function* generatePlan({ payload }: ReturnType<typeof generatePlanRequest>) {
    try {
        yield put(setCurrentPlanCompletion(false));

        const { id, current_day, days, yoga_type }: IPersonalListResponse = yield call(
            api.personalPlan.generatePersonalPlan,
            payload?.level
        );

        const { workouts, availableCurrentDay } = addWorkoutStatus({ workouts: days, currentDay: current_day });

        yield put(getCurrentPlanSuccess({ id, current_day: availableCurrentDay, days: workouts, yoga_type }));
    } catch (error: any) {
        console.error('Generate personal plan error: ', error.message);
    }
}

function* completeWorkout({ payload }: ReturnType<typeof workoutComplete>) {
    const { fact_kcal, fact_duration, onWorkoutCompleted, workoutDay } = payload;

    try {
        const currentPlanId: number = yield select(selectCurrentPlanId);
        const totalDays: number = yield select(selectTotalPlanDays);

        yield call(api.personalPlan.personalPlanForDay, {
            fact_kcal,
            fact_duration,
            id: currentPlanId,
            number: workoutDay,
        });

        if (isLastDayOfThePlan(workoutDay, totalDays)) {
            yield put(setCurrentPlanCompletion(true));
        }

        onWorkoutCompleted();
    } catch (error) {
        yield put(notifyError({ message: 'basics.appError' }));
    }
}

export default function* watchUser() {
    yield all([
        takeLatest(actionTypes.GET_COMPLETED_PLAN_STATISTICS, getCompletedPlanStatistics),
        takeLatest(actionTypes.GET_CURRENT_PLAN, getCurrentPlan),
        takeLatest(actionTypes.GENERATE_PLAN, generatePlan),
        takeLatest(actionTypes.COMPLETE_WORKOUT, completeWorkout),
    ]);
}
