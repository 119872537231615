import routes from 'router/routes';

import { getToken } from 'api/utils/tokenManagement';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import { useSelector } from 'hooks';

import * as S from './styled';

import logo from 'assets/images/logo.svg';
import logoLabel from 'assets/images/logo-label.svg';

import { ILogoProps } from './types';

const Logo = ({ withoutText, height, withLogo = true }: ILogoProps) => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const logoLink = isAuthenticated && getToken() ? routes.PRIVATE.MAIN.path : routes.PUBLIC.LOGIN.path;

    return (
        <S.LogoContainer to={logoLink} height={height}>
            {withLogo && <S.LogoImage maxWidth={27} alt="logo" src={logo} />}
            {!withoutText && <S.LogoText maxWidth={95} alt="label" src={logoLabel} />}
        </S.LogoContainer>
    );
};

export default Logo;
