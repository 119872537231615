import styled from 'styled-components';

export const Container = styled.div`
    max-width: 400px;
    margin: 0 auto;
    padding: 32px 16px 24px;
`;

export const ContainerDesktop = styled.div`
    margin: 0 auto;
    padding: 48px 16px 24px;
    max-width: 868px;
`;
