import { put, takeLatest, all, call } from 'redux-saga/effects';

import api from 'api';

import { setGuides, setLoadingState } from './actions';
import { notifyError } from '../notifications/actions';

import { FETCH_GUIDES } from './actionTypes';

import { IGuide } from 'types/guide';

function* getGuides() {
    try {
        const response: IGuide[] = yield call(api.user.getProducts);

        yield put(setGuides(response));
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError({ message: 'getGuides error' });
    }
}

export default function* watchGuides() {
    yield all([takeLatest(FETCH_GUIDES, getGuides)]);
}
