import { Text } from 'wikr-core-components';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import { NOTIFY_STATUSES, AUTO_CLOSE_DURATION } from 'constants/notifications';

import { CheckCircle, ErrorIcon } from 'components/Icon';

export type ShowNotifyType = {
    dataLocator?: string;
    message: string;
    status: NOTIFY_STATUSES;
    autoClose?: number;
};

export const Message = ({ message, locator }: { message: string; locator?: string }) => (
    <div data-locator={locator}>
        <Text type="small-text" color="text-main">
            <Trans i18nKey={message} />
        </Text>
    </div>
);

const showNotify = ({
    dataLocator,
    message,
    status = NOTIFY_STATUSES.DEFAULT,
    autoClose = AUTO_CLOSE_DURATION,
}: ShowNotifyType) => {
    if (status === NOTIFY_STATUSES.SUCCESS) {
        return toast.success(<Message message={message} locator={dataLocator || 'successAlertbox'} />, {
            icon: <CheckCircle />,
            autoClose,
        });
    }
    if (status === NOTIFY_STATUSES.ERROR) {
        return toast.error(<Message message={message} locator={dataLocator || 'failedAlertbox'} />, {
            icon: <ErrorIcon />,
            autoClose,
        });
    }

    return toast(<Message message={message} locator={dataLocator || 'alertbox'} />, {
        autoClose,
    });
};

export default showNotify;
