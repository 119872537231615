import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import HeaderPublic from 'components/Header/HeaderPublic';

import * as S from './styled';

import { LayoutProps } from '../types';

const AuthLayout = ({ children, titleKey }: LayoutProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t(titleKey);
    }, [t, titleKey]);

    return (
        <S.BodyContainer>
            <HeaderPublic />

            <S.MainContainer>{children}</S.MainContainer>
        </S.BodyContainer>
    );
};

export default AuthLayout;
