import { ActionEventAction, ElementEventAction, EventTrigger, EventTriggerFixed, Main } from 'wikr-core-analytics';

import { SUBSCRIPTION_STATES } from 'constants/subscriptions';
import { CANCEL_OFFER_SCREEN_ID, SUBSCRIPTION_SCREEN_ID } from 'constants/analytics';

import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import { CancelOfferAction } from 'types/subscription';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticCancelSubscriptionClick = ({
    external_id,
    sub_status,
    reminder = false,
    free_month = false,
    event_action,
}: {
    external_id: string;
    sub_status: SUBSCRIPTION_STATES;
    reminder?: boolean;
    free_month?: boolean;
    event_action?: string;
}) => {
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId: SUBSCRIPTION_SCREEN_ID },
        ...(!event_action && {
            actionData: {
                elementEvent: ElementEventAction.text,
                actionEvent: ActionEventAction.cancelSubscription,
            },
        }),
        data: {
            ...(event_action && { event_action }),
            event_label: {
                sub_status,
                reminder,
                subscription_id: [external_id],
                free_month,
            },
        },
    });
};

export const sendAnalyticContinueSubscriptionCancelClick = (eventLabel: {
    reason: CancellationReasonRetenoValue;
    subscription_id: string;
}) => {
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId: SUBSCRIPTION_SCREEN_ID },
        data: { event_label: eventLabel },

        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionData: { elementEvent: ElementEventAction.button, actionEvent: 'continue_cancel' },
    });
};

export const sendAnalyticSubscriptionCancelError = (eventLabel: string) => {
    // @todo: remove after lib will contain those events
    // @ts-ignore
    trackEventFixed('subscription_cancel_error', eventLabel);
};

export const sendAnalyticFrontCancelSubscriptionRequest = (eventLabel?: string) => {
    // @todo: remove after lib will contain those events
    // @ts-ignore
    trackEventFixed('front_cancel_subscription_request', eventLabel);
};

export const sendAnalyticSubscriptionCancelSuccess = (eventLabel: string) => {
    trackEventFixed(EventTriggerFixed.subscriptionCancelSuccess, eventLabel);
};

export const sendAnalyticCancelReasonContinueClick = (eventLabel: {
    reason: CancellationReasonRetenoValue;
    subscription_id: string;
}) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.quizContinue,
        eventLabel,
    });
};

export const sendAnalyticDiscountAppliedResult = (event: string, eventLabel: string) => {
    // @todo: remove after lib will contain those event triggers
    // @ts-ignore
    trackEventFixed(event, eventLabel);
};

export const sendAnalyticRestoreButtonClick = (
    eventType: string,
    eventLabel: object,
    actionEvent: string = EventTrigger.click
) => {
    trackClick({
        screenIdParam: eventType,
        // @ts-ignore
        elementEvent: ElementEventAction.button,
        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionEvent,
        eventLabel,
    });
};

export const sendAnalyticChangePaymentMethodButtonClick = (
    eventType: string,
    eventLabel: object,
    actionEvent: string = EventTrigger.click
) => {
    trackClick({
        screenIdParam: eventType,
        // @ts-ignore
        elementEvent: ElementEventAction.button,
        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionEvent,
        eventLabel,
    });
};

export const trackScreenLoadCancelOffer = ({
    screenId = CANCEL_OFFER_SCREEN_ID,
    eventLabel,
}: {
    screenId?: string;
    eventLabel?: { [key: string]: any };
} = {}) => {
    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const sendAnalyticCancelOfferClick = ({
    eventLabel,
    actionEvent,
    elementEvent,
    screenId = CANCEL_OFFER_SCREEN_ID,
}: {
    eventLabel?: { [key: string]: any };
    actionEvent?: CancelOfferAction;
    elementEvent?: ElementEventAction;
    screenId?: string;
} = {}) => {
    // @todo: remove after lib will contain those eventAction
    // @ts-ignore
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId },
        ...(actionEvent && {
            actionData: {
                elementEvent: elementEvent || ElementEventAction.button,
                actionEvent,
            },
        }),
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};
