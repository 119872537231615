import { css, styled } from 'styled-components';
import ReactModal from 'react-modal';

import { borderRadius, color } from 'theme/selectors';
import { mediaQueriesNew } from 'theme/mediaQueries';

const fullscreenContent = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* 100vh for older browsers, 100dvh for modern browsers with dynamic viewport handling */
    height: 100vh;
    height: 100dvh;
    overflow-y: scroll;
`;

const defaultContent = css`
    width: calc(100% - 32px);
    max-width: 432px;
    border-radius: ${borderRadius('medium')};
    margin: 16px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
`;

const mobileFullScreenStyles = css`
    margin: 0;
    max-width: unset;

    ${mediaQueriesNew.tablet} {
        ${defaultContent};
        position: relative;
        overflow: auto;
        height: fit-content;
    }
`;

export const ContentStyle = styled.div<{
    fullscreen?: boolean;
    paddingY?: number;
    paddingX?: number;
    mobileFullScreen?: boolean;
}>`
    padding: ${({ paddingX = 24, paddingY = 16 }) =>
        css`
            ${paddingX}px ${paddingY}px
        `};
    background-color: ${color('surface-main')};
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    text-align: left;
    position: relative;
    z-index: 10;

    ${({ fullscreen }) => (fullscreen ? fullscreenContent : defaultContent)}

    ${({ mobileFullScreen }) =>
        mobileFullScreen &&
        css`
            ${fullscreenContent}
            ${mobileFullScreenStyles}
        `}
`;

export const OverlayStyle = styled.div<{ fullscreen?: boolean }>`
    position: fixed;
    width: 100vw;
    /* 100vh for older browsers, 100dvh for modern browsers with dynamic viewport handling */
    height: 100vh;
    height: 100dvh;
    line-height: 100vh;
    line-height: 100dvh;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.7) !important;
    ${({ fullscreen }) =>
        !fullscreen &&
        css`
            overflow-y: scroll;
        `}
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 18px;
    background: transparent;
    right: 18px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;

    svg path {
        transition: all 0.3s ease;
    }

    &:hover {
        background: ${color('surface-secondary')};

        svg path {
            fill: ${color('text-main')};
        }
    }
`;

export const ModalWithStyles = styled(ReactModal)<{ withRoundedCorners: boolean; mobileFullScreen?: boolean }>`
    border-radius: 0;

    ${({ withRoundedCorners, mobileFullScreen }) => {
        if (withRoundedCorners)
            return css`
                border-radius: ${borderRadius('medium')};
            `;

        if (mobileFullScreen)
            return css`
                ${mediaQueriesNew.tablet} {
                    border-radius: ${borderRadius('medium')};
                }
            `;
    }}
`;
