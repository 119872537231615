import * as actionTypes from './actionTypes';

import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import {
    ISubscription,
    ICancelSubscription,
    IFetchDiscountSubscription,
    IDiscountSubscription,
    IUpdateSubscriptionData,
    SubscriptionPauseType,
} from 'types/subscription';

export const fetchSubscriptions = () => {
    return {
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS,
    } as const;
};

export const setSubscriptions = (payload: Array<ISubscription>) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const cancelSubscription = (payload: ICancelSubscription[]) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
};

export const resetSubscriptions = () => {
    return {
        type: actionTypes.RESET_SUBSCRIPTIONS,
    } as const;
};

export const sendFeedback = (payload: { reason: CancellationReasonRetenoValue }) => {
    return {
        type: actionTypes.SEND_SUBSCRIPTION_FEEDBACK,
        payload,
    } as const;
};

export const setCancelReason = (payload: CancellationReasonRetenoValue) => {
    return {
        type: actionTypes.SET_CANCEL_REASON,
        payload,
    } as const;
};

export const updateSubscription = (payload: IUpdateSubscriptionData) => {
    return {
        type: actionTypes.UPDATE_SUBSCRIPTION,
        payload,
    } as const;
};

export const setCancellationCandidates = (payload: ISubscription[] | null) => {
    return {
        type: actionTypes.SET_CANCELLATION_CANDIDATE,
        payload,
    } as const;
};

export const fetchDiscountSubscription = (payload: IFetchDiscountSubscription) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const setDiscountSubscription = (payload: IDiscountSubscription | null) => {
    return {
        type: actionTypes.SET_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const restoreSubscription = (payload: ISubscription) => {
    return {
        type: actionTypes.RESTORE_SUBSCRIPTION,
        payload,
    } as const;
};

export const pauseSubscription = (payload: { externalIds: string[]; pause_type: SubscriptionPauseType }) => {
    return {
        type: actionTypes.PAUSE_SUBSCRIPTION,
        payload,
    } as const;
};

export const resumeSubscription = (payload: { external_id: string; date: string }) => {
    return {
        type: actionTypes.RESUME_SUBSCRIPTION,
        payload,
    } as const;
};

export const setReminder = (payload: { externalIds: string[] }) => {
    return {
        type: actionTypes.SET_REMINDER,
        payload,
    } as const;
};
