import { useCallback } from 'react';

import { sendFeedback, setCancelReason } from 'store/subscriptions/actions';

import { sendAnalyticCancelReasonContinueClick, sendAnalyticContinueSubscriptionCancelClick } from 'services/analytics';

import useCancelSubscriptionFlow from 'hooks/subscriptions/useCancelSubscriptionFlow';
import { useDispatch } from 'hooks';
import useCancellationStrategy from '../../CancellationStrategies/hooks/useCancellationStrategy';

import { IUserFeedback } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

const useCancellationReasonSubmit = () => {
    const dispatch = useDispatch();

    const { cancellationCandidates } = useCancelSubscriptionFlow();

    const { determineCancelStrategy } = useCancellationStrategy();

    const onSubmitSurvey = useCallback(
        ({ reason: { value, flow } }: IUserFeedback) => {
            if (!cancellationCandidates) return;

            const { external_id } = cancellationCandidates[0];

            sendAnalyticContinueSubscriptionCancelClick({
                reason: value,
                subscription_id: external_id,
            });

            sendAnalyticCancelReasonContinueClick({
                reason: value,
                subscription_id: external_id,
            });

            dispatch(
                sendFeedback({
                    reason: value,
                })
            );

            dispatch(setCancelReason(value));

            determineCancelStrategy(flow);
        },
        [cancellationCandidates, dispatch, determineCancelStrategy]
    );

    return {
        onSubmitSurvey,
    };
};

export default useCancellationReasonSubmit;
