import { styled } from 'styled-components';

import { color } from 'theme/selectors';
import { mediaQueriesNew } from 'theme/mediaQueries';

export const HeaderWithLogo = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 28px;
    border-bottom: 1px solid ${color('border-default')};
    column-gap: 50px;

    svg {
        vertical-align: middle;
    }

    ${mediaQueriesNew.tablet} {
        column-gap: 20px;
    }
`;
