import { Box, Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { sendAnalyticCancelOfferClick, trackScreenLoadCancelOffer } from 'services/analytics';

import { useCancelSubscriptionFlow, useModals, useSelector } from 'hooks';

import { getValuesByKey } from 'helpers/utils';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import FullScreenModalHeader from 'components/ModalHeaders/FullScreenModalHeader';
import Modal from 'components/Modal/Modal';

import { CancelFlowOffers, CancelOfferAction, SubscriptionPauseType } from 'types/subscription';

import freeMonthImg from './images/freeMonth.webp';

const FreeMonthModal = () => {
    const { t } = useTranslation();
    const { closeModal, openModal } = useModals();

    const cancellationCandidates = useSelector(selectCancellationCandidates);

    const { onCancelSubscription, onRequestPause } = useCancelSubscriptionFlow();

    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    useEffect(() => {
        trackScreenLoadCancelOffer({
            eventLabel: { subscription_id, offer: CancelFlowOffers.FreeMonth },
        });
    }, [subscription_id]);

    const sendAnalytics = useCallback(
        (action: CancelOfferAction) => {
            sendAnalyticCancelOfferClick({
                eventLabel: { subscription_id, action, offer: CancelFlowOffers.FreeMonth },
            });
        },
        [subscription_id]
    );

    const onBackButtonClick = () => {
        sendAnalytics(CancelOfferAction.Close);

        openModal(ModalName.CancellationReasonModal);
    };

    const onClose = useCallback(() => {
        sendAnalytics(CancelOfferAction.Close);

        closeModal();
    }, [closeModal, sendAnalytics]);

    const onSubmit = () => {
        sendAnalytics(CancelOfferAction.Accept);

        onRequestPause(SubscriptionPauseType.FreeMonth);
    };

    const onCancel = () => {
        sendAnalytics(CancelOfferAction.Cancel);

        onCancelSubscription();
    };

    return (
        <Modal fullscreen isOpen customStyles={{ padding: 0 }} withRoundedCorners={false}>
            <FullScreenModalHeader closeModal={onClose} onBackButtonClick={onBackButtonClick} />

            <Box className="container-sm" paddingTop={32}>
                <Image src={freeMonthImg} alt="image" maxWidth={200} mb={26} center />
                <Text mb={20} type="h4" text={t('subscription.freeMonthModal.title')} medium center />
                <Text mb={8} type="large-text" text={t('subscription.freeMonthModal.subtitle')} center />
                <Text mb={32} type="h6" text={t('subscription.freeMonthModal.text1')} medium center />

                <Button
                    onClick={onSubmit}
                    text={t('subscription.freeMonthModal.acceptOfferBtn')}
                    dataLocator="getOneMonthButton"
                    mb={16}
                />
                <OutlinedButton
                    onClick={onCancel}
                    text={t('basics.button.cancelSubscription')}
                    backgroundColor="surface-main"
                    dataLocator="cancelSubscriptionButton"
                    mb={32}
                />
            </Box>
        </Modal>
    );
};

export default FreeMonthModal;
