export const DOWNLOAD_APP_EVENT_LABELS = {
    ios_top: 'ios_top',
    android_top: 'android_top',
    general_bot: 'general_bot',
    ios_bot: 'ios_bot',
    android_bot: 'android_bot',
};

export const SUBSCRIPTION_SCREEN_ID = 'account_subscription';
export const DISCOUNT_APPLIED_SUCCESS = 'discount_applied_success';
export const DISCOUNT_APPLIED_ERROR = 'discount_applied_error';
export const RESTORE_POPUP_ID = 'restore_popup';
export const SUBSCRIPTION_RENEWAL_FAILED = 'subscription_renewal_failed';
export const SUBSCRIPTION_RENEWAL_SUCCESS = 'subscription_renewal_success';
export const CANCEL_SUBSCRIPTION_SCREEN_ID = 'cancel_subscription';
export const CANCEL_OFFER_SCREEN_ID = 'cancel_offer';
export const CANCEL_OFFER_SUCCESS_SCREEN_ID = 'cancel_offer_success';
export const RENEW_SUCCESS_SCREEN_ID = 'renew_success';

// One time password
export const OTP_SCREEN_ID = 'log_in';
export const OTP_EVENT_LABEL = 'otp_code';
