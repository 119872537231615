import { Text } from 'wikr-core-components';
import { styled } from 'styled-components';

import { color } from 'theme/selectors';

export const StepItem = styled.div<{ mb?: number }>`
    position: relative;
    display: flex;
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};

    &:before {
        content: '';
        position: absolute;
        top: 55px;
        bottom: 0;
        left: 27px;
        width: 2px;
        background-color: ${color('surface-default')};
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: ${color('surface-default')};
`;

export const AccessItem = styled.div<{ mb?: number }>`
    padding: 16px;
    border-radius: 20px;
    background-color: ${color('surface-default')};
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};
`;

export const AccessItemHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: 56px;
    margin-bottom: 8px;
`;

export const GuideItem = styled(Text)`
    position: relative;
    padding-left: 20px;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: ${color('text-secondary')};
    }
`;
