import {
    CancellationReasonModal,
    ConfirmationModal,
    WithLoaderModal,
    DiscountModal,
    SuccessModal,
    NoConnectionModal,
    QuitWorkoutModal,
    CompletePersonalPlanModal,
    GoalModal,
    ProblemZonesModal,
    FitnessLevelModal,
    RestoreSubscriptionModal,
    RestoreSubscriptionErrorModal,
    CanNotLoginModal,
    ReminderModal,
    FreeMonthModal,
    RetryErrorModal,
    AdditionalContentOfferModal,
    PauseModal,
} from 'components/Modal';

import { ModalName, ModalsMapType } from './types';

export const MODALS_LIST: ModalsMapType = {
    [ModalName.DiscountModal]: DiscountModal,
    [ModalName.CancellationReasonModal]: CancellationReasonModal,
    [ModalName.ConfirmationModal]: ConfirmationModal,
    [ModalName.WithLoaderModal]: WithLoaderModal,
    [ModalName.SuccessModal]: SuccessModal,
    [ModalName.NoConnectionModal]: NoConnectionModal,
    [ModalName.QuitWorkoutModal]: QuitWorkoutModal,
    [ModalName.CompletePersonalPlanModal]: CompletePersonalPlanModal,
    [ModalName.GoalModal]: GoalModal,
    [ModalName.ProblemZonesModal]: ProblemZonesModal,
    [ModalName.FitnessLevelModal]: FitnessLevelModal,
    [ModalName.RestoreSubscriptionModal]: RestoreSubscriptionModal,
    [ModalName.RestoreSubscriptionErrorModal]: RestoreSubscriptionErrorModal,
    [ModalName.CanNotLoginModal]: CanNotLoginModal,
    [ModalName.ReminderModal]: ReminderModal,
    [ModalName.FreeMonthModal]: FreeMonthModal,
    [ModalName.RetryErrorModal]: RetryErrorModal,
    [ModalName.AdditionalContentOfferModal]: AdditionalContentOfferModal,
    [ModalName.PauseModal]: PauseModal,
};
