import { styled } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';

export const Container = styled.div<{ mb?: number }>`
    display: flex;
    flex-direction: column;
    background-color: ${color('surface-default')};
    border-radius: ${borderRadius('medium')};
    padding: 8px 16px;
    margin-bottom: ${({ mb = 0 }) => mb}px;
`;

export const FeatureItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 18px 0;
`;
