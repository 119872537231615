import WorkoutHistory from './icons/workoutHistory.svg?react';
import Library from './icons/library.svg?react';
import FitnessPlan from './icons/fitnessPlan.svg?react';
import customizable from './icons/customizable.svg?react';

export const features = [
    {
        feature: 'subscription.confirmationModal.feature1',
        icon: Library,
    },
    {
        feature: 'subscription.confirmationModal.feature2',
        icon: FitnessPlan,
    },
    {
        feature: 'subscription.confirmationModal.feature3',
        icon: WorkoutHistory,
    },
    {
        feature: 'subscription.confirmationModal.feature4',
        icon: customizable,
    },
];
