import { DISCOUNT_TYPES } from 'constants/subscriptions';

import ApiClient from 'services/ApiClient';

import {
    ISubscription,
    ISubscriptionFeedback,
    ICancelSubscriptionRequest,
    IDiscountSubscription,
    IUpdateSubscriptionData,
} from 'types/subscription';
import { IMessageResponse, IResultResponse, PauseSubscriptionRequestType } from '../types/response';

class SubscriptionsApi {
    getSubscriptions = async <Response = ISubscription>(): Promise<Partial<Response> | unknown> => {
        return await ApiClient.get<Response>('user/subscription');
    };

    unsubscribe = async <Data = ICancelSubscriptionRequest, Response = IResultResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<Data, Response>('solidgate/subscription/cancel', payload);
    };

    sendFeedback = async <Data = ISubscriptionFeedback, Response = IMessageResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<Data, Response>('feedback/', payload);
    };

    updateSubscription = async <Data = IUpdateSubscriptionData, Response = IResultResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<Data, Response>('solidgate/subscription/switch', payload);
    };

    getDiscountSubscription = async <Response = IDiscountSubscription>(payload: {
        external_id: string;
        discount_type: DISCOUNT_TYPES;
    }): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<typeof payload, Response>('solidgate/subscription/discount', payload);
    };

    restoreSubscription = async (payload: { external_id: string }): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<typeof payload, Response>('solidgate/subscription/restore', payload);
    };

    pauseSubscription = async (payload: PauseSubscriptionRequestType): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<typeof payload, Response>('solidgate/subscription/pause', payload);
    };

    resumeSubscription = async (payload: { external_id: string }): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<typeof payload, Response>('solidgate/subscription/resume', payload);
    };

    setReminder = async (payload: { external_id: string }): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<typeof payload, Response>('user/subscription/reminder', payload);
    };
}

export default SubscriptionsApi;
