import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import base from 'appConfig/base';

import { sendAnalyticResetContactUsClick } from 'services/analytics';

import { ContactUsLinkPropsType } from './types';

const ContactUsLink = ({ subject, mb, text = 'basics.contactUs' }: ContactUsLinkPropsType) => {
    const { t } = useTranslation();

    return (
        <a
            data-locator="contactUsLinkBtn"
            href={`mailto:${base.supportEmail}?subject=${t(subject)}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={sendAnalyticResetContactUsClick}
        >
            <Text dataLocator="contactButton" type="overline" center bold text={t(text)} mb={mb} />
        </a>
    );
};

export default ContactUsLink;
