import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import { SUBSCRIPTION_STATES } from 'constants/subscriptions';

import useCancelSubscriptionFlow from 'hooks/subscriptions/useCancelSubscriptionFlow';
import { checkDiscountAllowed } from 'hooks/subscriptions/helpers';
import { useModals } from 'hooks/modals';

import { isMainSubscription } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { CancellationFlowStrategy } from '../enums';

type StrategyMap = Partial<Record<CancellationFlowStrategy, () => void>>;

const useCancellationStrategy = () => {
    const { cancellationCandidates, onCancelSubscription } = useCancelSubscriptionFlow();

    const { product_code, discount_type, state, is_charge_reminder_on } = cancellationCandidates?.[0] ?? {};

    const { country } = useSelector(selectCurrentUser);

    const { openModal } = useModals();

    const runDiscountFlow = useCallback(() => {
        const isDiscountAllowed = checkDiscountAllowed(country, state);

        const isMain = product_code ? isMainSubscription(product_code) : false;

        isMain && !discount_type && isDiscountAllowed ? openModal(ModalName.DiscountModal) : onCancelSubscription();
    }, [country, discount_type, openModal, onCancelSubscription, product_code, state]);

    const runFreeMonthPauseFlow = useCallback(
        (modalId: ModalName) => {
            const isFreeMonthAllowed = state !== SUBSCRIPTION_STATES.Paused;

            isFreeMonthAllowed ? openModal(modalId) : onCancelSubscription();
        },
        [openModal, onCancelSubscription, state]
    );

    const runReminderFlow = useCallback(
        () => (is_charge_reminder_on ? onCancelSubscription() : openModal(ModalName.ReminderModal)),
        [onCancelSubscription, is_charge_reminder_on, openModal]
    );

    const strategies: StrategyMap = useMemo(
        () => ({
            [CancellationFlowStrategy.Discount]: runDiscountFlow,
            [CancellationFlowStrategy.HelpLogin]: () => openModal(ModalName.CanNotLoginModal),
            [CancellationFlowStrategy.Reminder]: runReminderFlow,
            [CancellationFlowStrategy.FreeMonth]: () => runFreeMonthPauseFlow(ModalName.FreeMonthModal),
            [CancellationFlowStrategy.Pause]: () => runFreeMonthPauseFlow(ModalName.PauseModal),
            [CancellationFlowStrategy.ChangePlan]: () => openModal(ModalName.AdditionalContentOfferModal),
        }),
        [runDiscountFlow, openModal, runFreeMonthPauseFlow, runReminderFlow]
    );

    const determineCancelStrategy = useCallback(
        (flow: CancellationFlowStrategy | undefined) => {
            const strategy = flow && strategies[flow];

            strategy ? strategy() : onCancelSubscription();
        },
        [onCancelSubscription, strategies]
    );

    return { determineCancelStrategy };
};

export default useCancellationStrategy;
