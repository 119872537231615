import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { sendAnalyticCancelOfferClick } from 'services/analytics';

import useModals from 'hooks/modals/useModals';
import { useCancelSubscriptionFlow, useSelector } from 'hooks';

import { getValuesByKey } from 'helpers/utils';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import FullScreenModalHeader from 'components/ModalHeaders/FullScreenModalHeader';
import Modal from 'components/Modal';
import InstallAppSteps from 'components/InstallAppSteps';

import { CancelFlowOffers, CancelOfferAction } from 'types/subscription';

import * as S from './styles';

const offer = CancelFlowOffers.Login;

const CanNotLoginModal = () => {
    const { t } = useTranslation();
    const { closeModal, openModal } = useModals();
    const cancellationCandidates = useSelector(selectCancellationCandidates);

    const { onCancelSubscription } = useCancelSubscriptionFlow();

    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    const onBackButtonClick = () => {
        sendAnalyticCancelOfferClick({ eventLabel: { subscription_id, offer }, actionEvent: CancelOfferAction.Close });

        openModal(ModalName.CancellationReasonModal);
    };

    const onClose = useCallback(() => {
        sendAnalyticCancelOfferClick({ eventLabel: { subscription_id, offer }, actionEvent: CancelOfferAction.Close });

        closeModal();
    }, [closeModal, subscription_id]);

    return (
        <Modal fullscreen isOpen customStyles={{ padding: 0 }} withRoundedCorners={false}>
            <FullScreenModalHeader closeModal={onClose} onBackButtonClick={onBackButtonClick} />

            <S.Container>
                <Text medium center mb={12} type="h4" text={t('cancellationReasons.canNotLogin.modal.title')} />
                <Text center mb={32} type="large-text" text={t('cancellationReasons.canNotLogin.modal.subtitle')} />

                <InstallAppSteps
                    stepsNumber={{
                        getAppStepNumber: 1,
                        logInStepNumber: 2,
                    }}
                    downloadButtonText="cancellationReasons.canNotLogin.modal.goToApp"
                    cancelOfferName={offer}
                />

                <S.CancelSubscriptionButton>
                    <OutlinedButton
                        onClick={() => {
                            sendAnalyticCancelOfferClick({
                                eventLabel: { subscription_id, offer },
                                actionEvent: CancelOfferAction.Cancel,
                            });
                            onCancelSubscription();
                        }}
                        text={t('basics.button.cancelSubscription')}
                        backgroundColor="surface-main"
                        dataLocator="helpLoginCancelSubscriptionButton"
                    />
                </S.CancelSubscriptionButton>
            </S.Container>
        </Modal>
    );
};

export default CanNotLoginModal;
