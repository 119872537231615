import wallPilates from '../../img/wallPilates.webp';
import somaticYoga from '../../img/somaticYoga.webp';
import classicYogaImg from '../../img/classicYoga.webp';
import chairYogaWithJohn from '../../img/chairYogaWithJohn.webp';
import chairYogaWithAnke from '../../img/chairYogaWithAnke.webp';

import PersonalPlanCard from '../PersonalPlanCard';

const configPPCard = [
    {
        title: 'subscription.additionalContentOfferModal.modal.card.title1',
        text: 'subscription.additionalContentOfferModal.modal.card.text1',
        image: classicYogaImg,
    },
    {
        title: 'subscription.additionalContentOfferModal.modal.card.title2',
        text: 'subscription.additionalContentOfferModal.modal.card.text2',
        image: chairYogaWithJohn,
    },
    {
        title: 'subscription.additionalContentOfferModal.modal.card.title3',
        text: 'subscription.additionalContentOfferModal.modal.card.text3',
        image: chairYogaWithAnke,
    },
    {
        title: 'subscription.additionalContentOfferModal.modal.card.title4',
        text: 'subscription.additionalContentOfferModal.modal.card.text4',
        image: wallPilates,
    },
    {
        title: 'subscription.additionalContentOfferModal.modal.card.title5',
        text: 'subscription.additionalContentOfferModal.modal.card.text5',
        image: somaticYoga,
    },
];

const PersonalPlanCards = () => {
    return configPPCard.map((card, index) => <PersonalPlanCard {...card} key={index} />);
};

export default PersonalPlanCards;
