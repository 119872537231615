import { SUBSCRIPTION_STATES } from 'constants/subscriptions';
import { COUNTRIES_WITHOUT_DISCOUNT_OFFER } from 'constants/countries';

import { ISubscription } from 'types/subscription';

export const checkDiscountAllowed = (country: string | null, state: SUBSCRIPTION_STATES | undefined) => {
    if (state === SUBSCRIPTION_STATES.Paused) {
        return false;
    }

    return country ? !COUNTRIES_WITHOUT_DISCOUNT_OFFER.includes(country) : false;
};

export const getSubscriptionIdsWithoutReminder = (subscriptions: ISubscription[]) =>
    subscriptions.reduce((acc, { is_charge_reminder_on, external_id }) => {
        if (!is_charge_reminder_on) {
            acc.push(external_id);
        }

        return acc;
    }, [] as string[]);

export const getActiveSubscriptionIds = (subscriptions: ISubscription[]) =>
    subscriptions.reduce((acc, { external_id, state }) => {
        if (state === SUBSCRIPTION_STATES.ACTIVE) {
            acc.push(external_id);
        }

        return acc;
    }, [] as string[]);
