import { Text, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import { PersonalPlanCardPropsType } from './types';

const PersonalPlanCard = ({ title, text, image }: PersonalPlanCardPropsType) => {
    const { t } = useTranslation();

    return (
        <S.PersonalPlanCard>
            <Image alt="personal plan card" src={image} />
            <S.WrapperText>
                <Text text={t(title)} type="h6" color="surface-default" />
                <Text text={t(text)} type="small-text" color="surface-default" />
            </S.WrapperText>
        </S.PersonalPlanCard>
    );
};

export default PersonalPlanCard;
