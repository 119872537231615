import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import {
    cancelSubscription,
    fetchDiscountSubscription,
    pauseSubscription,
    resumeSubscription,
    setCancellationCandidates,
    setReminder,
    updateSubscription,
} from 'store/subscriptions/actions';

import { DISCOUNT_TYPES, SUBSCRIPTION_STATES } from 'constants/subscriptions';

import { sendAnalyticCancelSubscriptionClick } from 'services/analytics';

import { useDispatch, useModals, useSelector } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';
import { checkDiscountAllowed, getActiveSubscriptionIds, getSubscriptionIdsWithoutReminder } from './helpers';

import { ModalName } from 'components/Modals/types';

import { CancelFlowOffers, ISubscription, SubscriptionPauseType } from 'types/subscription';

const useCancelSubscriptionFlow = () => {
    const dispatch = useDispatch();

    const { cancellationCandidates, discountSubscription, subscriptions } = useSelector(selectSubscriptions);

    const { country } = useSelector(selectCurrentUser);

    const { openModal } = useModals();

    const initSubscriptionCancellation = useCallback(
        (candidate: ISubscription[]) => {
            const { product_code, external_id, discount_type, state, pause_type, is_charge_reminder_on } = candidate[0];
            const isCancellationCandidateMain = isMainSubscription(product_code);
            const isDiscountAllowed = checkDiscountAllowed(country, state);

            if (isCancellationCandidateMain && !discount_type && isDiscountAllowed) {
                dispatch(
                    fetchDiscountSubscription({
                        discount_type: DISCOUNT_TYPES.base,
                        external_id: external_id,
                    })
                );
            }

            openModal(ModalName.ConfirmationModal);

            dispatch(setCancellationCandidates(candidate));
            sendAnalyticCancelSubscriptionClick({
                external_id,
                sub_status: state,
                free_month: pause_type === SubscriptionPauseType.FreeMonth,
                reminder: is_charge_reminder_on,
                ...(candidate.length > 1 && { event_action: 'cancel_all_subscriptions' }),
            });
        },
        [dispatch, openModal, country]
    );

    const onCancelSubscription = useCallback(
        (wasSecondCancelled?: boolean) => {
            if (!cancellationCandidates) return;

            dispatch(
                cancelSubscription(
                    cancellationCandidates.map((candidate) => {
                        const secondSubscription = subscriptions.find((e) => e.product_code !== candidate.product_code);

                        return {
                            currentSubscription: candidate,
                            ...(!wasSecondCancelled && { secondSubscription }),
                        };
                    })
                )
            );
        },
        [cancellationCandidates, dispatch, subscriptions]
    );

    const onChangeSubscription = useCallback(() => {
        if (!discountSubscription || !cancellationCandidates) return;

        dispatch(
            updateSubscription({
                product: discountSubscription,
                external_id: cancellationCandidates[0].external_id,
                discount_type: DISCOUNT_TYPES.base,
            })
        );
    }, [discountSubscription, dispatch, cancellationCandidates]);

    const onResumeSubscription = useCallback(
        (external_id: string, date: string) => {
            dispatch(resumeSubscription({ external_id, date }));

            sendAnalyticCancelSubscriptionClick({
                external_id,
                sub_status: SUBSCRIPTION_STATES.Paused,
                event_action: CancelFlowOffers.Resume,
            });
        },
        [dispatch]
    );

    const onRequestPause = useCallback(
        (pauseType: SubscriptionPauseType) => {
            dispatch(
                pauseSubscription({
                    pause_type: pauseType,
                    externalIds: getActiveSubscriptionIds(cancellationCandidates ?? []),
                })
            );
        },
        [dispatch, cancellationCandidates]
    );

    const onRequestSetReminder = useCallback(() => {
        dispatch(
            setReminder({
                externalIds: getSubscriptionIdsWithoutReminder(cancellationCandidates ?? []),
            })
        );
    }, [dispatch, cancellationCandidates]);

    return {
        initSubscriptionCancellation,
        cancellationCandidates,
        onCancelSubscription,
        onChangeSubscription,
        discountSubscription,
        onResumeSubscription,
        onRequestSetReminder,
        onRequestPause,
    };
};

export default useCancelSubscriptionFlow;
