export const AUTHENTICATE = 'AUTH/AUTHENTICATE';
export const SET_AUTHENTICATION_STATUS = 'AUTH/SET_AUTHENTICATION_STATUS';
export const LOG_OUT = 'AUTH/LOG_OUT';
export const SET_AUTHENTICATION_REDIRECT_URL = 'AUTH/SET_AUTHENTICATION_REDIRECT_URL';
export const AUTHENTICATE_BY_SIGNATURE = 'AUTH/AUTHENTICATE_BY_SIGNATURE';

export const SIGN_IN_REQUEST = 'AUTH/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'AUTH/SIGN_IN_SUCCESS';

export const SET_AUTH_TOKEN = 'AUTH/SET_AUTH_TOKEN';

export const SEND_TEMPORARY_PASSWORD = 'AUTH/SEND_TEMPORARY_PASSWORD';
