import { Colors } from 'wikr-core-components';
import { ExecutionProps } from 'styled-components/dist/types';
import { css } from 'styled-components';

import { withAlpha } from './helpers';

export const color =
    (colorKey: Colors, alpha?: number) =>
    ({ theme }: ExecutionProps) => {
        const themeColor = theme?.[colorKey];

        if (!themeColor) return undefined;

        return alpha ? withAlpha(themeColor, alpha) : themeColor;
    };

export const borderRadius =
    (size: 'small' | 'medium') =>
    ({ theme }: ExecutionProps) => {
        const radius = theme?.[`border-radius-${size}`] || 0;

        return `${radius}px`;
    };

export const viewPortWithoutHeader = () => css`
calc(100vh - 56px);  /* Fallback for older browsers */
calc(100dvh - 56px); /* Modern browsers */
`;
