import { SUBSCRIPTION_STATES } from 'constants/subscriptions';

import { ICancelSubscription, ISubscription } from 'types/subscription';

export const checkSubscriptionsWithReminders = (externalIds: string[]) => (subscriptions: ISubscription[]) =>
    externalIds.every((externalId) =>
        subscriptions.some(
            (subscription) => subscription.external_id === externalId && subscription.is_charge_reminder_on
        )
    );

export const checkAllCancelledSubscriptions =
    (cancellationCandidates: ICancelSubscription[]) => (subscriptions: ISubscription[]) =>
        cancellationCandidates.every((candidate) =>
            subscriptions.some(
                (subscription) => subscription.id === candidate.currentSubscription.id && !!subscription.cancelled_at
            )
        );

export const checkAllSubscriptionsWithDiscount = () => (subscriptions: ISubscription[]) =>
    subscriptions.some(({ discount_type }) => discount_type);

export const checkAllPausedSubscriptions = (externalIds: string[]) => (subscriptions: ISubscription[]) =>
    externalIds.every((externalId) =>
        subscriptions.some(
            (subscription) =>
                subscription.external_id === externalId && subscription.state === SUBSCRIPTION_STATES.Paused
        )
    );

export const checkAllResumedSubscriptions = (externalId: string) => (subscriptions: ISubscription[]) =>
    subscriptions.some(({ external_id, pause_to }) => external_id === externalId && pause_to === null);
