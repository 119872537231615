import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import * as S from './styled';

import sandClock from 'assets/emoji/hourglass_flowing_sand.webp';
import loader from './img/loader.svg';

import { WithLoaderModalPropsType } from './types';

const WithLoaderModal = ({
    img = sandClock,
    title,
    subtitle = 'subscription.waitingModal.pleaseWait.subttile',
    withRoundedCorners = true,
}: WithLoaderModalPropsType) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen customStyles={{ padding: '16px 16px 32px' }} withRoundedCorners={withRoundedCorners}>
            <S.LoaderContainer data-locator="processingBlock">
                {img && <Image maxWidth={72} center alt="emoji" src={img} isForceVisible />}
                <Text type="h5" text={t(title)} mb={subtitle ? 12 : 24} center dataLocator="processingTitle" />
                {subtitle && (
                    <S.Subtitle
                        medium
                        color="text-secondary"
                        text={t(subtitle)}
                        mb={24}
                        center
                        dataLocator="processingSubtitle"
                    />
                )}
                <Image dataLocator="processingSpiner" maxWidth={32} center alt="loader" src={loader} isForceVisible />
            </S.LoaderContainer>
        </Modal>
    );
};

export default WithLoaderModal;
