import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const PersonalPlanCard = styled(Box)`
    background-color: ${color('surface-main')};
    position: relative;
    margin-bottom: 12px;
`;

export const WrapperText = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
