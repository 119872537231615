/* eslint-disable max-lines */
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import { getUserFeatures } from 'store/user/actions';
import { closeModal, openModal } from 'store/modals/actions';

import {
    cancelSubscription,
    fetchDiscountSubscription,
    fetchSubscriptions,
    pauseSubscription,
    restoreSubscription,
    resumeSubscription,
    sendFeedback,
    setCancellationCandidates,
    setDiscountSubscription,
    setReminder,
    setSubscriptions,
    updateSubscription,
} from './actions';
import { notifyError } from '../notifications/actions';

import {
    CANCEL_SUBSCRIPTION,
    FETCH_DISCOUNT_SUBSCRIPTION,
    FETCH_USER_SUBSCRIPTIONS,
    PAUSE_SUBSCRIPTION,
    RESTORE_SUBSCRIPTION,
    RESUME_SUBSCRIPTION,
    SEND_SUBSCRIPTION_FEEDBACK,
    SET_REMINDER,
    UPDATE_SUBSCRIPTION,
} from './actionTypes';

import {
    CANCEL_OFFER_SUCCESS_SCREEN_ID,
    DISCOUNT_APPLIED_ERROR,
    DISCOUNT_APPLIED_SUCCESS,
    RENEW_SUCCESS_SCREEN_ID,
} from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import {
    sendAnalyticDiscountAppliedResult,
    sendAnalyticFrontCancelSubscriptionRequest,
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelSuccess,
    trackScreenLoadCancelOffer,
} from 'services/analytics';

import {
    changeSubscriptionErrorModalData,
    getCancelSubscriptionErrorModalData,
    getCancelSubscriptionSuccessModalData,
    getChangeSubscriptionSuccessModalData,
    getFreeMonthSuccessModalData,
    pauseSuccessModalData,
    getRestoreSuccessModalData,
    getResumeSuccessModalData,
    reminderSuccessModalData,
    setReminderErrorModalData,
    getRequestPauseSubscriptionErrorModalData,
    getRequestPauseSubscriptionWaitingModalData,
    getRequestResumeSubscriptionErrorModalData,
} from 'helpers/subscriptions';
import {
    checkAllCancelledSubscriptions,
    checkAllPausedSubscriptions,
    checkAllResumedSubscriptions,
    checkAllSubscriptionsWithDiscount,
    checkSubscriptionsWithReminders,
} from './helpers';

import { ModalName } from 'components/Modals/types';
import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import {
    CancelFlowOffers,
    ICancelSubscription,
    IDiscountSubscription,
    ISubscription,
    SubscriptionPauseType,
} from 'types/subscription';

import { selectCancelReason } from './selectors';

export function* getSubscription() {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        yield put(setSubscriptions(response));

        return response;
    } catch (error) {
        yield put(closeModal());
        yield put(notifyError({ message: 'basics.appError' }));
    }
}

function* makeSubscriptionCancelling({ payload: cancellationCandidates }: ReturnType<typeof cancelSubscription>) {
    const successCount = { current: 0 };
    const errorCount = { current: [] };

    yield put(
        openModal(ModalName.WithLoaderModal, {
            title: 'subscription.withLoaderModal.cancellingPlan',
            img: null,
        })
    );

    try {
        const cancelReason: CancellationReasonRetenoValue | null = yield select(selectCancelReason);

        yield all(
            cancellationCandidates.map((candidate) =>
                call(callUnsubscribe, candidate, successCount, errorCount, cancelReason)
            )
        );
    } catch (error) {
        const [firstSubscription] = cancellationCandidates;

        sendAnalyticSubscriptionCancelError(firstSubscription.currentSubscription.external_id);
    } finally {
        const successTotal = successCount.current;
        const errorTotal = errorCount.current.length;

        if (errorTotal > 0) {
            yield callErrorCancellingBehaviour({ errorCount, cancellationCandidates });
        } else if (successTotal > 0) {
            yield call(pollGetSubscriptions, checkAllCancelledSubscriptions(cancellationCandidates));
            yield callSuccessCancellingBehaviour({ cancellationCandidates });
        }
    }
}

function* callErrorCancellingBehaviour({
    errorCount,
    cancellationCandidates,
}: {
    errorCount: Record<string, string[]>;
    cancellationCandidates: ICancelSubscription[];
}) {
    const subscriptionsCancellationFailedPartially = errorCount.current.length < cancellationCandidates.length;

    if (subscriptionsCancellationFailedPartially) {
        const candidate = cancellationCandidates.find(
            (e) => e.currentSubscription.external_id === errorCount.current[0]
        );

        yield put(setCancellationCandidates(candidate ? [candidate.currentSubscription] : null));
    }

    yield put(
        openModal(ModalName.RetryErrorModal, {
            ...getCancelSubscriptionErrorModalData({
                isSingleError: errorCount.current.length > 1,
                subscriptionsCancellationFailedPartially,
            }),
            retryAction: () => cancelSubscription(cancellationCandidates),
        })
    );
}

function* callSuccessCancellingBehaviour({
    cancellationCandidates,
}: {
    cancellationCandidates: ICancelSubscription[];
}) {
    const [firstCandidate, secondCandidate] = cancellationCandidates;

    yield put(
        openModal(
            ModalName.SuccessModal,
            getCancelSubscriptionSuccessModalData([
                firstCandidate.currentSubscription,
                secondCandidate?.currentSubscription,
            ])
        )
    );
    yield put(setCancellationCandidates(null));
}

function* sendSubscriptionFeedback({ payload }: ReturnType<typeof sendFeedback>) {
    try {
        yield call(api.subscriptions.sendFeedback, payload);
    } catch (error) {
        console.error(error);
    }
}

function* callUnsubscribe(
    { currentSubscription }: { currentSubscription: ICancelSubscription['currentSubscription'] },
    successCount: { current: number },
    errorCount: { current: string[] },
    cancelReason: CancellationReasonRetenoValue | null
) {
    try {
        sendAnalyticFrontCancelSubscriptionRequest();

        const response: IResultResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: currentSubscription.external_id,
            ...(cancelReason && { cancellation_reason: cancelReason }),
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        sendAnalyticSubscriptionCancelSuccess(currentSubscription.external_id);

        successCount.current += 1;
    } catch (error) {
        errorCount.current.push(currentSubscription.external_id);

        sendAnalyticSubscriptionCancelError(currentSubscription.external_id);
    }
}

function* changeCurrentSubscription({ payload }: ReturnType<typeof updateSubscription>) {
    try {
        yield put(openModal(ModalName.WithLoaderModal, { title: 'subscription.waitingModal.updatingPlan.title' }));

        const { result }: IResultResponse = yield api.subscriptions.updateSubscription(payload);

        if (!result) {
            throw Error('changeCurrentSubscription returns false');
        }

        yield call(pollGetSubscriptions, checkAllSubscriptionsWithDiscount());

        yield call(trackScreenLoadCancelOffer, {
            screenId: CANCEL_OFFER_SUCCESS_SCREEN_ID,
            eventLabel: { subscription_id: payload.external_id, offer: CancelFlowOffers.Discount },
        });

        yield put(openModal(ModalName.SuccessModal, getChangeSubscriptionSuccessModalData(payload.product)));

        yield call(sendAnalyticDiscountAppliedResult, DISCOUNT_APPLIED_SUCCESS, payload.product.name);
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...changeSubscriptionErrorModalData,
                retryAction: () => updateSubscription(payload),
            })
        );

        yield call(
            sendAnalyticDiscountAppliedResult,
            DISCOUNT_APPLIED_ERROR,
            'Subscription plan not updated due to a technical issue'
        );
    }
}

function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscription>) {
    try {
        const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscription, payload);

        yield delay(3000);
        yield put(setDiscountSubscription(response));
    } catch (error) {
        console.error(error);
    }
}

function* requestRestoreSubscription({ payload }: ReturnType<typeof restoreSubscription>) {
    const { external_id, product } = payload;

    try {
        yield call(api.subscriptions.restoreSubscription, { external_id });

        yield put(openModal(ModalName.SuccessModal, getRestoreSuccessModalData(product)));

        yield call(trackScreenLoad, 'restore_success', {
            subscription_id: external_id,
            period: product.subscription_period,
            tariff: product.name,
            content_id: product.id,
        });

        yield put(fetchSubscriptions());
        yield put(getUserFeatures());
    } catch (error) {
        yield put(openModal(ModalName.RestoreSubscriptionErrorModal, payload));

        console.error(error);
    }
}

export function* requestPauseSubscription({ payload }: ReturnType<typeof pauseSubscription>) {
    try {
        yield put(
            openModal(ModalName.WithLoaderModal, getRequestPauseSubscriptionWaitingModalData(payload.pause_type))
        );

        yield all(
            payload.externalIds.map((external_id) =>
                call(api.subscriptions.pauseSubscription, { external_id, pause_type: payload.pause_type })
            )
        );

        const [firstSubscription]: ISubscription[] = yield call(
            pollGetSubscriptions,
            checkAllPausedSubscriptions(payload.externalIds)
        );

        const successModalInfo =
            payload.pause_type === SubscriptionPauseType.Pause
                ? pauseSuccessModalData
                : getFreeMonthSuccessModalData(firstSubscription.expired_date);

        if (firstSubscription) {
            yield call(trackScreenLoadCancelOffer, {
                screenId: CANCEL_OFFER_SUCCESS_SCREEN_ID,
                eventLabel: { subscription_id: payload.externalIds, offer: successModalInfo.offer },
            });

            yield put(openModal(ModalName.SuccessModal, successModalInfo));
        }
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...getRequestPauseSubscriptionErrorModalData(payload.externalIds, payload.pause_type),
                retryAction: () => pauseSubscription(payload),
            })
        );
    }
}

export function* pollGetSubscriptions(conditionCheck: (data: ISubscription[]) => boolean) {
    let isConditionSatisfied = false;

    let result: ISubscription[] = [];

    while (!isConditionSatisfied) {
        result = yield call(getSubscription);

        isConditionSatisfied = !result || conditionCheck(result);

        if (!isConditionSatisfied) {
            // Wait for 2 seconds before polling again
            yield delay(2000);
        }
    }

    return result ?? [];
}

export function* requestResumeSubscription({ payload }: ReturnType<typeof resumeSubscription>) {
    try {
        yield put(
            openModal(ModalName.WithLoaderModal, {
                title: 'subscription.waitingModal.updatingPlan.title',
                img: null,
            })
        );

        yield call(api.subscriptions.resumeSubscription, { external_id: payload.external_id });

        const result: ISubscription[] = yield call(
            pollGetSubscriptions,
            checkAllResumedSubscriptions(payload.external_id)
        );

        const currentSubscription: ISubscription | undefined = result.find(
            ({ external_id }: ISubscription) => external_id === payload.external_id
        );

        if (currentSubscription) {
            yield call(trackScreenLoadCancelOffer, {
                screenId: RENEW_SUCCESS_SCREEN_ID,
                eventLabel: { subscription_id: payload.external_id },
            });

            yield put(
                openModal(
                    ModalName.SuccessModal,
                    getResumeSuccessModalData(currentSubscription.cancelled_at ?? currentSubscription.expired_date)
                )
            );
        }
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...getRequestResumeSubscriptionErrorModalData([payload.external_id]),
                retryAction: () =>
                    pauseSubscription({ externalIds: [payload.external_id], pause_type: SubscriptionPauseType.Pause }),
            })
        );

        console.error(error);
    }
}

export function* requestSetReminder({ payload }: ReturnType<typeof setReminder>) {
    try {
        yield put(
            openModal(ModalName.WithLoaderModal, {
                title: 'basics.loading',
                img: null,
                subtitle: null,
            })
        );

        yield all(payload.externalIds.map((external_id) => call(api.subscriptions.setReminder, { external_id })));

        yield call(pollGetSubscriptions, checkSubscriptionsWithReminders(payload.externalIds));

        yield put(openModal(ModalName.SuccessModal, reminderSuccessModalData));

        yield call(trackScreenLoadCancelOffer, {
            screenId: CANCEL_OFFER_SUCCESS_SCREEN_ID,
            eventLabel: { subscription_id: payload.externalIds, offer: CancelFlowOffers.Reminder },
        });
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...setReminderErrorModalData,
                retryAction: () => setReminder(payload),
            })
        );
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeLatest(FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(SEND_SUBSCRIPTION_FEEDBACK, sendSubscriptionFeedback),
        takeLatest(UPDATE_SUBSCRIPTION, changeCurrentSubscription),
        takeLatest(FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(RESTORE_SUBSCRIPTION, requestRestoreSubscription),
        takeLatest(PAUSE_SUBSCRIPTION, requestPauseSubscription),
        takeLatest(RESUME_SUBSCRIPTION, requestResumeSubscription),
        takeLatest(SET_REMINDER, requestSetReminder),
    ]);
}
