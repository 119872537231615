import { Button } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FeedbackModalTemplate } from 'components/Modals/Templates';

import { RetryErrorModalPropsType } from './types';

const RetryErrorModal = ({ img, title, subtitle, subject, retryAction }: RetryErrorModalPropsType) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const contactUsData = subject
        ? {
              subject,
              text: 'basics.contactSupport',
          }
        : null;

    return (
        <FeedbackModalTemplate image={img} title={title} subtitle={subtitle} contactUs={contactUsData} withCloseButton>
            <Button
                backgroundColor="primary-default"
                text={t('basics.tryAgain')}
                onClick={() => dispatch(retryAction())}
                mb={subject ? 24 : 0}
            />
        </FeedbackModalTemplate>
    );
};

export default RetryErrorModal;
