import { Provider } from 'wikr-core-analytics';
import { call, takeLatest, all, put, delay } from 'redux-saga/effects';

import api from 'api';

import {
    getCurrentUserRequest,
    getCurrentUserSuccess,
    getCurrentUserError,
    updateUserRequest,
    updateUserSuccess,
    updateUserError,
    updateUserEmail,
    setUserFeatures,
} from './actions';
import { notifyError, notifySuccess } from '../notifications/actions';

import { GET_CURRENT_USER_REQUEST, UPDATE_USER_REQUEST, UPDATE_USER_EMAIL, GET_USER_FEATURES } from './actionTypes';

import { getBirthdayByAge } from 'helpers/date';
import { userAPIResponseTransformer } from 'helpers/api';

import { UserFeature } from 'types/user/userApiInterface';
import { UserResponse } from 'types/store/userResponse';

export function* getCurrentUser({ payload }: ReturnType<typeof getCurrentUserRequest>) {
    try {
        const userData: UserResponse = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userData)));
        Provider.setUserId(userData.user_id);

        payload?.onSuccess?.(userData);
    } catch (error: any) {
        yield put(notifyError({ message: 'message.error.somethingWentWrong' }));
        yield put(getCurrentUserError(error.error));
        payload?.onError?.(error);
    }
}

function* updateUser({ payload: { payload, onSuccess, onError } }: ReturnType<typeof updateUserRequest>) {
    try {
        const { age, ...restUserData } = payload;

        const userData: UserResponse = yield call(api.user.update, {
            ...restUserData,
            date_of_birth: age && getBirthdayByAge(age),
        });

        Provider.setUserId(userData.user_id);
        yield put(updateUserSuccess(userData));
        yield put(notifySuccess({ message: 'message.success.changesSaved' }));

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError({ message: 'message.error.changesNotSaved' }));

        onError && onError();
    }
}

function* updateUserEmailRequest({ payload: { email, onSuccess, onError } }: ReturnType<typeof updateUserEmail>) {
    try {
        const userData: UserResponse = yield call(api.user.update, {
            email,
        });

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess({ message: 'change.email.success' }));

        // Fix closing notification on redirect
        yield delay(500);

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError({ message: 'change.email.error' }));

        onError && onError();
    }
}

function* getFeatures() {
    try {
        const { features } = yield call(api.user.getFeatures);

        const webAvailableFeatures = features.filter((feature: UserFeature) => feature !== UserFeature.AllGuides);

        yield put(setUserFeatures(webAvailableFeatures));
    } catch (error) {
        console.error(error);
    }
}

export default function* watchUser() {
    yield all([
        takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser),
        takeLatest(UPDATE_USER_REQUEST, updateUser),
        takeLatest(UPDATE_USER_EMAIL, updateUserEmailRequest),
        takeLatest(GET_USER_FEATURES, getFeatures),
    ]);
}
