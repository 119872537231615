import { CancellationFlowStrategy } from '../CancellationStrategies/enums';

export interface IUserFeedback {
    reason: IReason;
}

export enum CancellationReasonRetenoValue {
    ForgetToCancel = 'forget_to_cancel',
    TooExpensive = 'too_expensive',
    HaveNoTime = 'have_no_time',
    NoNeedForApp = 'no_need_for_app',
    DontLikeWorkouts = 'dont_like_workouts',
    DoesntMeetExpectations = 'doesnt_meet_expectations',
    CantLogin = 'cant_login',
    Other = 'other',
}

export interface IReason {
    title: string;
    value: CancellationReasonRetenoValue;
    flow?: CancellationFlowStrategy;
}
