import { all, takeLatest } from 'redux-saga/effects';

import { signInSaga } from './signInSaga';
import { sendTemporaryPasswordSaga } from './sendTemporaryPasswordSaga';
import { logoutSaga } from './logoutSaga';
import { authenticateSaga } from './authenticateSaga';
import { authenticateBySignatureSaga } from './authenticateBySignatureSaga';
import {
    AUTHENTICATE,
    AUTHENTICATE_BY_SIGNATURE,
    LOG_OUT,
    SEND_TEMPORARY_PASSWORD,
    SIGN_IN_REQUEST,
} from '../actionTypes';

export { signInSaga } from './signInSaga';
export { authenticateSaga } from './authenticateSaga';
export { authenticateBySignatureSaga } from './authenticateBySignatureSaga';
export { logoutSaga } from './logoutSaga';

export default function* watchAuth() {
    yield all([
        takeLatest(LOG_OUT, logoutSaga),
        takeLatest(SIGN_IN_REQUEST, signInSaga),
        takeLatest(AUTHENTICATE, authenticateSaga),
        takeLatest(AUTHENTICATE_BY_SIGNATURE, authenticateBySignatureSaga),
        takeLatest(SEND_TEMPORARY_PASSWORD, sendTemporaryPasswordSaga),
    ]);
}
