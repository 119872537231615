import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import * as S from './styles';

type AppFeaturesProps = {
    features: { feature: string; icon: ComponentType }[];
    mb?: number;
};

const AppFeatures = ({ features, mb }: AppFeaturesProps) => {
    const { t } = useTranslation();

    return (
        <S.Container mb={mb}>
            {features.map(({ feature, icon: Icon }) => (
                <S.FeatureItem data-testid="feature-item" key={feature}>
                    <Icon />
                    <Text text={t(feature)} opacityValue={0.8} />
                </S.FeatureItem>
            ))}
        </S.Container>
    );
};

export default AppFeatures;
