import * as actionTypes from './actionTypes';

import { ShowNotifyType } from 'services/toast';

type NotifyPayloadType = Omit<ShowNotifyType, 'status'>;

export const notify = (payload: NotifyPayloadType) => {
    return {
        type: actionTypes.DISPLAY_DEFAULT_NOTIFICATION,
        payload,
    } as const;
};

export const notifySuccess = (payload: NotifyPayloadType) => {
    return {
        type: actionTypes.DISPLAY_SUCCESS_NOTIFICATION,
        payload,
    } as const;
};

export const notifyError = (payload: NotifyPayloadType) => {
    return {
        type: actionTypes.DISPLAY_ERROR_NOTIFICATION,
        payload,
    } as const;
};
