import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { sendAnalyticCancelOfferClick, trackScreenLoadCancelOffer } from 'services/analytics';

import useModals from 'hooks/modals/useModals';
import { useCancelSubscriptionFlow, useSelector } from 'hooks';

import { getValuesByKey } from 'helpers/utils';
import isMobile from 'helpers/isMobile';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import FullScreenModalHeader from 'components/ModalHeaders/FullScreenModalHeader';
import Modal from 'components/Modal';
import InstallAppSteps from 'components/InstallAppSteps';
import PersonalPlanCards from './components/PersonalPlanCards';

import { CancelFlowOffers, CancelOfferAction } from 'types/subscription';

import * as S from './styles';

const deepLinkPP = 'https://yogago.onelink.me/vaBt/zos8l5ya';

const AdditionalContentOfferModal = () => {
    const { t } = useTranslation();
    const { closeModal, openModal } = useModals();
    const cancellationCandidates = useSelector(selectCancellationCandidates);
    const [isExploreYogaTypesDesktop, setExploreYogaTypesDesktop] = useState(false);
    const { onCancelSubscription } = useCancelSubscriptionFlow();

    const offer = CancelFlowOffers.ChangePlan;

    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    useEffect(() => {
        trackScreenLoadCancelOffer({ eventLabel: { subscription_id, offer } });
    }, []);

    const isDesktop = !isMobile.any();

    const onBackButtonClick = () => {
        sendAnalyticCancelOfferClick({ eventLabel: { subscription_id, offer }, actionEvent: CancelOfferAction.Close });

        if (isExploreYogaTypesDesktop) {
            setExploreYogaTypesDesktop(false);
        } else {
            openModal(ModalName.CancellationReasonModal);
        }
    };

    const onClose = useCallback(() => {
        sendAnalyticCancelOfferClick({ eventLabel: { subscription_id, offer }, actionEvent: CancelOfferAction.Close });

        closeModal();
    }, [closeModal, subscription_id]);

    const handleDownloadApp = () => {
        if (isDesktop) {
            setExploreYogaTypesDesktop(true);
        } else {
            window.open(deepLinkPP, '_blank', 'noopener,noreferrer');
        }

        sendAnalyticCancelOfferClick({
            eventLabel: { subscription_id, offer },
            actionEvent: CancelOfferAction.Accept,
        });
    };

    return (
        <Modal fullscreen isOpen customStyles={{ padding: 0 }} withRoundedCorners={false}>
            <FullScreenModalHeader closeModal={onClose} onBackButtonClick={onBackButtonClick} />

            {isExploreYogaTypesDesktop ? (
                <S.ContainerDesktop>
                    <Text
                        medium
                        center
                        mb={24}
                        type="h4"
                        text={t('subscription.additionalContentOfferModal.modal.titleDesktop')}
                    />
                    <InstallAppSteps
                        stepsNumber={{
                            getAppStepNumber: 1,
                            logInStepNumber: 2,
                        }}
                        downloadButtonText="cancellationReasons.canNotLogin.modal.goToApp"
                        customLink={deepLinkPP}
                        cancelOfferName={offer}
                    />
                </S.ContainerDesktop>
            ) : (
                <S.Container>
                    <Text mb={16} type="h6" text={t('subscription.additionalContentOfferModal.modal.title')} />
                    <Text
                        mb={24}
                        color="text-secondary"
                        text={t('subscription.additionalContentOfferModal.modal.disclaimer')}
                    />

                    <PersonalPlanCards />

                    <Button
                        dataLocator="deeplinkBtn"
                        onClick={handleDownloadApp}
                        backgroundColor="primary-default"
                        mb={24}
                        text={t('subscription.additionalContentOfferModal.modal.exploreYogaTypes')}
                    />

                    <OutlinedButton
                        onClick={() => {
                            sendAnalyticCancelOfferClick({
                                eventLabel: { subscription_id, offer },
                                actionEvent: CancelOfferAction.Cancel,
                            });
                            onCancelSubscription();
                        }}
                        text={t('basics.cancelBtn.cancelAnyway')}
                        backgroundColor="surface-main"
                        dataLocator="helpLoginCancelSubscriptionButton"
                    />
                </S.Container>
            )}
        </Modal>
    );
};

export default AdditionalContentOfferModal;
