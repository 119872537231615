import { Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { useModals } from 'hooks';

import { SuccessModalPropsType } from './types';

import { FeedbackModalTemplate } from '../../Templates';

const dataLocators = {
    body: 'successfulBlock',
};

const SuccessModal = ({
    image,
    title,
    subtitle,
    buttonText,
    withCloseButton,
    withRoundedCorners,
}: SuccessModalPropsType) => {
    const { closeModal } = useModals();

    const { t } = useTranslation();

    return (
        <FeedbackModalTemplate
            dataLocators={dataLocators}
            image={image}
            title={title}
            subtitle={subtitle}
            withCloseButton={withCloseButton}
            withRoundedCorners={withRoundedCorners}
            isSuccessModal
        >
            <Button
                dataLocator="successContinueButton"
                backgroundColor="primary-default"
                text={t(buttonText)}
                onClick={closeModal}
            />
        </FeedbackModalTemplate>
    );
};

export default SuccessModal;
