import { Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { sendAnalyticCancelOfferClick, trackScreenLoadCancelOffer } from 'services/analytics';

import { useCancelSubscriptionFlow } from 'hooks/subscriptions';
import useModals from 'hooks/modals/useModals';
import { useSelector } from 'hooks';

import { getValuesByKey } from 'helpers/utils';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import FullScreenModalHeader from 'components/ModalHeaders/FullScreenModalHeader';
import Modal from 'components/Modal';

import * as S from './styled';

import star from './img/star.svg';

import { CancelFlowOffers, CancelOfferAction, SubscriptionPauseType } from 'types/subscription';

const PauseModal = () => {
    const { closeModal, openModal } = useModals();
    const { t } = useTranslation();

    const cancellationCandidates = useSelector(selectCancellationCandidates);

    const { onCancelSubscription, onRequestPause } = useCancelSubscriptionFlow();

    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    useEffect(() => {
        trackScreenLoadCancelOffer({
            eventLabel: { subscription_id, offer: CancelFlowOffers.Pause },
        });
    }, [subscription_id]);

    const sendAnalytics = useCallback(
        (actionEvent: CancelOfferAction) => {
            sendAnalyticCancelOfferClick({
                actionEvent,
                eventLabel: { subscription_id, offer: CancelFlowOffers.Pause },
            });
        },
        [subscription_id]
    );

    const onBackButtonClick = () => {
        sendAnalytics(CancelOfferAction.Close);

        openModal(ModalName.CancellationReasonModal);
    };

    const onCloseButtonClick = useCallback(() => {
        sendAnalytics(CancelOfferAction.Close);

        closeModal();
    }, [closeModal, sendAnalytics]);

    const onPauseButtonClick = () => {
        sendAnalytics(CancelOfferAction.Accept);

        onRequestPause(SubscriptionPauseType.Pause);
    };

    const onCancelButtonClick = () => {
        sendAnalytics(CancelOfferAction.Cancel);
        onCancelSubscription();
    };

    return (
        <Modal fullscreen isOpen onClose={onCloseButtonClick} customStyles={{ padding: 0 }} withRoundedCorners={false}>
            <FullScreenModalHeader onBackButtonClick={onBackButtonClick} closeModal={onCloseButtonClick} />

            <div className="container-sm">
                <Box paddingTop={32} paddingBottom={32}>
                    <Text medium center mb={12} type="h4" text={t('subscription.pauseModal.title')} />
                    <Text center mb={24} type="large-text" text={t('subscription.pauseModal.subtitle')} />

                    <S.Card data-locator="pauseSubscriptionDealBlock">
                        <S.CardHeader
                            text={t('subscription.pauseModal.card.label')}
                            color="decorative-c"
                            medium
                            center
                        />
                        <S.CardContent>
                            <Text medium center mb={20} type="h5" text={t('subscription.pauseModal.card.title')} />
                            <S.ListItem>
                                <S.ItemImage src={star} alt="star" maxWidth={16} />
                                <Text text={t('subscription.pauseModal.card.item1')} />
                            </S.ListItem>
                            <S.ListItem>
                                <S.ItemImage src={star} alt="star" maxWidth={16} />
                                <Text text={t('subscription.pauseModal.card.item2')} />
                            </S.ListItem>
                            <S.ListItem>
                                <S.ItemImage src={star} alt="star" maxWidth={16} />
                                <Text text={t('subscription.pauseModal.card.item3')} />
                            </S.ListItem>

                            <S.PauseButton
                                backgroundColor="primary-default"
                                text={t('subscription.pauseModal.pauseBtn')}
                                onClick={onPauseButtonClick}
                                dataLocator="pauseSubscriptionButton"
                            />
                        </S.CardContent>
                    </S.Card>
                    <OutlinedButton
                        onClick={onCancelButtonClick}
                        text={t('basics.button.cancelSubscription')}
                        backgroundColor="surface-main"
                        dataLocator="cancelSubscriptionButton"
                    />
                </Box>
            </div>
        </Modal>
    );
};

export default PauseModal;
