import { put, call } from 'redux-saga/effects';

import api from 'api';

import { notifyError } from 'store/notifications/actions';

import { sendTemporaryPassword } from '../actions';

import { LS_KEYS } from 'helpers/localStorage';

export function* sendTemporaryPasswordSaga({ payload }: ReturnType<typeof sendTemporaryPassword>) {
    const { email, onSuccess, onError } = payload;

    try {
        yield call(api.user.sendTemporaryPassword, { email });

        localStorage.setItem(LS_KEYS.tmpPasswordEmail, email);

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (error: any) {
        yield put(notifyError({ message: 'message.error.somethingWentWrong' }));

        if (error?.message && onError) yield call(onError, error.message);
    }
}
