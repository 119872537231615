import * as action from './actions';

import * as actionTypes from './actionTypes';

import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import { IDiscountSubscription, ISubscription } from 'types/subscription';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

export interface SubscriptionsState {
    subscriptions: ISubscription[];
    cancellationCandidates: ISubscription[] | null;
    discountSubscription: IDiscountSubscription | null;
    cancelReason: CancellationReasonRetenoValue | null;
}

export const initialState = {
    subscriptions: [],
    cancellationCandidates: null,
    discountSubscription: null,
    cancelReason: null,
};

const subscriptionsReducer = (state: SubscriptionsState = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.RESET_SUBSCRIPTIONS:
            return { ...initialState };

        case actionTypes.SET_USER_SUBSCRIPTIONS:
            return { ...state, subscriptions: action.payload };

        case actionTypes.SET_CANCELLATION_CANDIDATE:
            return { ...state, cancellationCandidates: action.payload };

        case actionTypes.SET_DISCOUNT_SUBSCRIPTION:
            return { ...state, discountSubscription: action.payload };

        case actionTypes.SET_CANCEL_REASON:
            return { ...state, cancelReason: action.payload };

        default:
            return state;
    }
};

export default subscriptionsReducer;
