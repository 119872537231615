import config from 'config';

import { gridBreakpoints } from 'theme/mediaQueries';

export const isEmpty = (value: null | undefined | string | number | object): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && Number(value) === Infinity)
    );
};

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');

export const getWindowSize = (params = '(min-width: 1024px)') => {
    const mediaQuery = window.matchMedia(params);

    return mediaQuery.matches;
};

export const isTablet = () => getWindowSize(`(min-width: ${gridBreakpoints.tablet}px)`);

export const isProd = () => config.ENV === 'prod';

export const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (character) {
        const randomValue = (Math.random() * 16) | 0;
        const value = character === 'x' ? randomValue : (randomValue & 0x3) | 0x8;

        return value.toString(16);
    });

export const roundToNearest = (number: number, multiple = 10) => Math.round(number / multiple) * multiple;

export const getValuesByKey = <T>(array: T[] | null, ...keys: (keyof any)[]): any[] => {
    if (!array || array.length === 0) return [];

    return array
        .map((item) => {
            let value: any = item;

            for (const key of keys) {
                if (value === null || value === undefined) return undefined;
                value = value[key];
            }

            return value;
        })
        .filter((value) => value !== null && value !== undefined);
};
